import { graphql } from "../../gql";

export const GET_BRANDS = graphql(`
  query GetBrands($options: BrandListOptions) {
    brands(options: $options) {
      items {
        id
        name
        token
      }
      totalItems
    }
  }
`);
