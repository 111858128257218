import { graphql } from "../gql";

export const ORIGIN_COUNTRY_FRAGMENT = graphql(`
  fragment OriginCountry on OriginCountry {
    id
    token
    country {
      id
      name
      code
    }
  }
`);

export const GET_ORIGIN_COUNTRIES = graphql(`
  query GetOriginCountries($options: OriginCountryListOptions) {
    originCountries(options: $options) {
      items {
        ...OriginCountry
      }
      totalItems
    }
  }
`);

export const GET_ORIGIN_COUNTRY = graphql(`
  query GetOriginCountry($id: ID!) {
    originCountry(id: $id) {
      ...OriginCountry
    }
  }
`);

export const CREATE_ORIGIN_COUNTRY = graphql(`
  mutation CreateOriginCountry($input: CreateOriginCountryInput!) {
    createOriginCountry(input: $input) {
      ...OriginCountry
    }
  }
`);

export const UPDATE_ORIGIN_COUNTRY = graphql(`
  mutation UpdateOriginCountry($input: UpdateOriginCountryInput!) {
    updateOriginCountry(input: $input) {
      ...OriginCountry
    }
  }
`);
