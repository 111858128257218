import {
  addNavMenuItem,
  addActionBarItem,
  ActionBarContext,
  registerDashboardWidget,
  registerPageTab,
  PageLocationId,
} from "@vendure/admin-ui/core";
import { RelationBrandInputComponent } from "./components/relation-brand-input/relation-brand-input.component";
import { registerFormInputComponent } from "@vendure/admin-ui/core";
import { RelationOriginCountryInputComponent } from "./components/relation-origin-country-input/relation-origin-country-input.component";
import { RelationStrainInputComponent } from "./components/relation-strain-input/relation-strain-input.component";
import { fileToJson } from "./helpers/file-to-json";
import { BatchCreateProductInput } from "./gql/graphql";
import { BATCH_CREATE_PRODUCTS } from "./graphql/batch.graphql";
import { SEND_ORDER_EMAIL } from "./graphql/order.graphql";
import { firstValueFrom } from "rxjs";
import { FlavorListComponent } from "./components/flavor-list/flavor-list.component";

export default [
  addNavMenuItem(
    {
      id: "brands",
      label: "Brands",
      routerLink: ["extensions", "leaftec-shop-vendure", "brands"],
      icon: "star",
      requiresPermission: "ReadBrand",
    },
    "catalog"
  ),
  addNavMenuItem(
    {
      id: "origin-countries",
      label: "Origin countries",
      routerLink: ["extensions", "leaftec-shop-vendure", "origin-countries"],
      icon: "flag",
      requiresPermission: "ReadOriginCountry",
    },
    "catalog"
  ),
  addNavMenuItem(
    {
      id: "flavors",
      label: "Flavors",
      routerLink: ["extensions", "leaftec-shop-vendure", "flavors"],
      icon: "helix",
      requiresPermission: "ReadFlavor",
    },
    "catalog"
  ),
  addNavMenuItem(
    {
      id: "strains",
      label: "Strains",
      routerLink: ["extensions", "leaftec-shop-vendure", "strains"],
      icon: "helix",
      requiresPermission: "ReadStrain",
    },
    "catalog"
  ),
  addNavMenuItem(
    {
      id: "shop-product-variants",
      label: "Produkte",
      routerLink: [
        "extensions",
        "leaftec-shop-vendure",
        "shop-product-variants",
      ],
      requiresPermission: "ReadShopProductVariant",
    },
    "catalog"
  ),
  addNavMenuItem(
    {
      id: "my-shop",
      label: "Mein Shop",
      icon: "store",
      routerLink: ["extensions", "leaftec-shop-vendure", "my-shop"],
    },
    "catalog"
  ),
  // overriding default
  addNavMenuItem(
    {
      id: "products",
      label: "Produkte",
      routerLink: ["catalog", "products"],
      icon: "library",
      requiresPermission(userPermissions) {
        return (
          userPermissions.includes("SuperAdmin") ||
          (userPermissions.includes("ReadProducts") &&
            !userPermissions.includes("ReadShopProductVariant"))
        );
      },
    },
    "catalog"
  ),
  // overriding default
  addNavMenuItem(
    {
      id: "assets",
      label: "Assets",
      routerLink: ["catalog", "assets"],
      icon: "image-gallery",
      requiresPermission(userPermissions) {
        return (
          userPermissions.includes("SuperAdmin") ||
          (userPermissions.includes("ReadAssets") &&
            !userPermissions.includes("ReadShopProductVariant"))
        );
      },
    },
    "catalog"
  ),
  // overriding default
  addNavMenuItem(
    {
      id: "sellers",
      label: "Verkäufer",
      routerLink: ["settings", "sellers"],
      icon: "store",
      requiresPermission(userPermissions) {
        return (
          userPermissions.includes("SuperAdmin") ||
          (userPermissions.includes("ReadSeller") &&
            !userPermissions.includes("ReadShopProductVariant"))
        );
      },
    },
    "settings"
  ),
  // overriding default
  addNavMenuItem(
    {
      id: "channels",
      label: "Kanäle",
      routerLink: ["settings", "channels"],
      icon: "layers",
      requiresPermission(userPermissions) {
        return (
          userPermissions.includes("SuperAdmin") ||
          (userPermissions.includes("ReadChannel") &&
            !userPermissions.includes("ReadShopProductVariant"))
        );
      },
    },
    "settings"
  ),
  addActionBarItem({
    id: "batch-create-products",
    label: "Batch create products",
    locationId: "product-list",
    requiresPermission: "SuperAdmin",
    onClick: batchCreateProducts,
  }),
  addActionBarItem({
    id: "send-order-email",
    label: "Zahlungsaufforderung senden",
    locationId: "order-detail",
    onClick: async (event, context) => {
      let clicked = false;

      const entity = await firstValueFrom(context.entity$);

      if (entity?.__typename === "Order" && !clicked) {
        // Logger.info("entity" + JSON.stringify(entity));
        console.log("entity", entity);
        context.dataService
          .mutate(SEND_ORDER_EMAIL, {
            orderId: entity?.id,
          })
          .subscribe((result) => {
            clicked = true;
            console.log("result", result);
          });
      }
    },
  }),
  registerDashboardWidget("stock-chart", {
    title: "Bestände",
    supportedWidths: [6, 8, 12],
    requiresPermissions: ["ReadShopProductVariant"],
    loadComponent: () =>
      import("./components/stock-widget/stock-chart-widget.component").then(
        (m) => m.StockChartWidgetComponent
      ),
  }),
  registerFormInputComponent(
    "relation-brand-input",
    RelationBrandInputComponent
  ),
  registerFormInputComponent(
    "relation-origin-country-input",
    RelationOriginCountryInputComponent
  ),
  registerFormInputComponent(
    "relation-strain-input",
    RelationStrainInputComponent
  ),
];

function batchCreateProducts(event: Event, context: ActionBarContext) {
  const fileInput = document.createElement("input");
  fileInput.style.display = "none";
  fileInput.type = "file";

  // Append the input to the body (or any other element, if needed)
  document.body.appendChild(fileInput);

  // Listen for the file selection
  fileInput.addEventListener("change", async (event: Event) => {
    const file = (event.target as HTMLInputElement)?.files?.[0];

    if (file) {
      const fileContent = await fileToJson<BatchCreateProductInput[]>(file);

      context.dataService
        .mutate(BATCH_CREATE_PRODUCTS, {
          input: fileContent,
        })
        .subscribe((result) => {
          console.log({
            result,
            fileContent,
          });
        });
    } else {
      console.log("No file selected.");
    }
  });

  // Trigger the file input click event to prompt file selection
  fileInput.click();
}
