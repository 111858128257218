import { Component, OnInit } from "@angular/core";
import { RelationCustomFieldConfig } from "@vendure/common/lib/generated-types";
import { FormInputComponent, SharedModule } from "@vendure/admin-ui/core";
import { FormControl } from "@angular/forms";
import { DataService } from "@vendure/admin-ui/core";
import { Observable } from "rxjs";
import { GET_STRAINS } from "../../graphql/strain.graphql";
import { GetStrainsQuery } from "../../gql/graphql";

type Strain = GetStrainsQuery["strains"]["items"][number];
@Component({
  selector: "lsv-relation-strain-input",
  templateUrl: "./relation-strain-input.component.html",
  standalone: true,
  imports: [SharedModule],
})
export class RelationStrainInputComponent
  implements OnInit, FormInputComponent<RelationCustomFieldConfig>
{
  readonly: boolean;
  formControl: FormControl;
  config: RelationCustomFieldConfig;

  strains$: Observable<Strain[]>;

  constructor(private readonly dataService: DataService) {}

  ngOnInit() {
    this.strains$ = this.dataService
      .query(GET_STRAINS)
      .mapSingle((data) => data.strains.items);
  }

  compareFunction(strain1: Strain | null, strain2: Strain | null) {
    return strain1?.id === strain2?.id;
  }
}
