import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { RelationCustomFieldConfig } from "@vendure/common/lib/generated-types";
import { FormInputComponent, SharedModule } from "@vendure/admin-ui/core";
import { Observable } from "rxjs";
import { GetOriginCountriesQuery } from "../../gql/graphql";
import { DataService } from "@vendure/admin-ui/core";
import { GET_ORIGIN_COUNTRIES } from "../../graphql/origin-country.graphql";

type OriginCountry =
  GetOriginCountriesQuery["originCountries"]["items"][number];

@Component({
  selector: "lsv-relation-origin-country-input",
  templateUrl: "./relation-origin-country-input.component.html",
  standalone: true,
  imports: [SharedModule],
})
export class RelationOriginCountryInputComponent
  implements OnInit, FormInputComponent<RelationCustomFieldConfig>
{
  readonly: boolean;
  formControl: FormControl;
  config: RelationCustomFieldConfig;

  originCountries$: Observable<OriginCountry[]>;

  constructor(private readonly dataService: DataService) {}

  ngOnInit() {
    this.originCountries$ = this.dataService
      .query(GET_ORIGIN_COUNTRIES)
      .mapSingle((data) => data.originCountries.items);
  }

  compareFunction(
    originCountry1: OriginCountry | null,
    originCountry2: OriginCountry | null
  ) {
    return originCountry1?.id === originCountry2?.id;
  }
}
