import { graphql } from "../gql";

export const BATCH_CREATE_BRANDS = graphql(`
  mutation BatchCreateBrands($input: [BatchCreateBrandInput!]!) {
    batchCreateBrands(input: $input) {
      result
    }
  }
`);

export const BATCH_CREATE_STRAINS = graphql(`
  mutation BatchCreateStrains($input: [BatchCreateStrainInput!]!) {
    batchCreateStrains(input: $input) {
      result
    }
  }
`);

export const BATCH_CREATE_PRODUCTS = graphql(`
  mutation BatchCreateProducts($input: [BatchCreateProductInput!]!) {
    batchCreateProducts(input: $input) {
      result
    }
  }
`);
