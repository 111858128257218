import { graphql } from "../gql";

export const STRAIN_FRAGMENT = graphql(`
  fragment Strain on Strain {
    id
    token
    name
    terpenePortion
    translations {
      id
      languageCode
      localizedDescription
    }
    flavors {
      id
      localizedName
      translations {
        id
        languageCode
        localizedName
      }
    }
  }
`);

export const GET_STRAINS = graphql(`
  query GetStrains($options: StrainListOptions) {
    strains(options: $options) {
      items {
        ...Strain
      }
      totalItems
    }
  }
`);

export const GET_STRAIN = graphql(`
  query GetStrain($id: ID!) {
    strain(id: $id) {
      ...Strain
    }
  }
`);

export const CREATE_STRAIN = graphql(`
  mutation CreateStrain($input: CreateStrainInput!) {
    createStrain(input: $input) {
      ...Strain
    }
  }
`);

export const UPDATE_STRAIN = graphql(`
  mutation UpdateStrain($input: UpdateStrainInput!) {
    updateStrain(input: $input) {
      ...Strain
    }
  }
`);
