export async function fileToJson<T>(file: File) {
  return new Promise<T>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(JSON.parse(reader.result as string));
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}
