import { addNavMenuItem, addNavMenuSection } from "@vendure/admin-ui/core";

export default [
  addNavMenuSection({ id: "seo", label: "SEO", items: [] }),
  addNavMenuItem(
    {
      id: "seo-pages",
      label: "SEO Pages",
      routerLink: ["extensions", "seo", "seo-pages"],
      icon: "application",
    },
    "seo"
  ),
];
