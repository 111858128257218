import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { RelationCustomFieldConfig } from "@vendure/common/lib/generated-types";
import {
  FormInputComponent,
  DataService,
  SharedModule,
} from "@vendure/admin-ui/core";
import { Observable } from "rxjs";
import { GET_BRANDS } from "../brands/brands.graphql";
import { GetBrandsQuery } from "../../gql/graphql";

type Brand = GetBrandsQuery["brands"]["items"][number];

@Component({
  selector: "lsv-relation-brand-input",
  templateUrl: "./relation-brand-input.component.html",
  standalone: true,
  imports: [SharedModule],
})
export class RelationBrandInputComponent
  implements OnInit, FormInputComponent<RelationCustomFieldConfig>
{
  readonly: boolean;
  formControl: FormControl;
  config: RelationCustomFieldConfig;

  brands$: Observable<Brand[]>;

  constructor(private readonly dataService: DataService) {}

  ngOnInit() {
    this.brands$ = this.dataService
      .query(GET_BRANDS)
      .mapSingle((data) => data.brands.items);
  }

  compareFunction(brand1: Brand | null, brand2: Brand | null) {
    return brand1?.id === brand2?.id;
  }
}
